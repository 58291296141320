<template>
<div class="display-block">
    <h2>Import {{importFile}}</h2>
    <v-form>
        <v-checkbox label="Include first line as header?" v-model="header"></v-checkbox>
        <v-flex>
            <file-upload @input="readFile()" v-model="csvFile" label="Add CSV File"></file-upload>
        </v-flex>
        <v-flex v-if="items.length==0">
            <h3>Select Header Fields</h3>
            <v-layout v-for="(data,index) in csvStringArray[0]" :key="index">
                <v-flex sm2 ma-6><label>{{data}}</label></v-flex>
                <v-flex sm3>
                    <v-select v-model="fields[index]" class="pr-12" label="Select Field" :items="attributes"></v-select>
                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex v-if="items.length>0">
            <v-row>
                <v-flex xs12 sm1 ma-3>Summmary :</v-flex>
                <v-flex xs4 sm1 ma-3>Success: {{successCount}}</v-flex>
                <v-flex xs4 sm7 ma-3>Failed: {{failedCount}}</v-flex>
                <v-flex mx-4 xs10 sm2>
                    <v-select label="Show Records" :items="showRecords" v-model="show"></v-select>
                </v-flex>
            </v-row>
        </v-flex>
        <v-flex v-if="items.length>0">
            <v-data-table :headers="headers" :items="filteredArr" class="elevation-1" v-if="$vuetify.breakpoint.smAndUp">
                <template v-slot:item.rec="{item}">
                    {{item.rec}}
                </template>
                <template v-slot:item.msg="{item}">
                    <template v-if="item.msg&&!item.success">
                        <label>{{item.msg}}</label>
                    </template>
                </template>
                <template v-slot:item.success="{item}">
                    <label v-if="item.success">Success</label>
                    <v-icon v-if="item.success" class="active-icon">mdi-check-circle-outline</v-icon>
                    <label v-if="!item.success">Failed</label>
                    <v-icon v-if="!item.success" class="deactive-icon">mdi-cancel</v-icon>
                </template>
            </v-data-table>
            <template v-if="$vuetify.breakpoint.xsOnly">
                <view-card :arrItems="filteredCardArr" :show="show"></view-card>
            </template>
        </v-flex>
        <v-flex text-right>
            <v-btn v-if="items.length==0" class="ma-2" :style="theme" @click="submit()">Import Data</v-btn>
            <v-btn class="ma-2" :style="themeInverted" @click="$router.go(-1)">Back</v-btn>
        </v-flex>
    </v-form>
</div>
</template>

<script>
import ViewCard from '@/components/ImportFileCard'
import appConstants from '@/utils/appConstants'
export default {
    components: {
        ViewCard,
    },
    data() {
        return {
            csvFile: '',
            importFile: '',
            csvStringArray: [],
            header: true,
            attributes: [],
            stringArray: [],
            fields: [],
            items: [],
            headers: [],
            successCount:0,
            failedCount:0,
            showRecords:['All','Failed'],
            show:'',
            cardItems:[]
        }
    },
    computed: {
        filteredArr() {
            var itemArr=[]
            if(this.show=='Failed'){
                this.items.forEach(item=>{
                    if(!item.success)
                        itemArr.push(item)
                })
                return itemArr
            }
            else
                return this.items
        },
        filteredCardArr(){
            var itemArr=[]
            if(this.show=='Failed'){
                this.cardItems.forEach(item=>{
                    if(!item.success)
                        itemArr.push(item)
                })
                return itemArr
            }
            else
                return this.cardItems
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.importFile = this.$route.params.importFile
            this.attributes = await this.getItem(appConstants.IMPORT_FILE+"/"+this.importFile)
        },
        readFile() {
            const csvData = this.csvFile
            var reader = new FileReader()
            reader.readAsText(csvData)
            reader.onload = event => {
                let text = reader.result
                var firstLine = text.split('\n');
                if (!this.header) 
                    this.stringArray.push(firstLine[0])
                else
                    this.stringArray.push(firstLine[1])
                this.csvStringArray.push(this.stringArray[0].split(","))
            }
        },
        async submit() {
            if(!this.header)
                this.fields.push({'noheader':true})
            let formData = this.convertToFormData(this.fields, [this.csvFile], 'csvFile')
            if (this.csvFile != '') {
                if (this.importFile == 'products')
                    this.items = await this.postItem(appConstants.IMPORT_FILE + "/product", formData)
                else if (this.importFile == 'brands')
                    this.items = await this.postItem(appConstants.IMPORT_FILE + "/brand", formData)
                else if (this.importFile == 'categories')
                    this.items = await this.postItem(appConstants.IMPORT_FILE + "/category", formData)
                else if (this.importFile == 'customers')
                    this.items = await this.postItem(appConstants.IMPORT_FILE + "/customer", formData)
                this.setHeader()
            } else
                alert("Please select file to Upload")
        },
        setHeader(){
            this.items.forEach(rec=>{
                if(rec.failedCount)
                    this.failedCount+=1
                else
                    this.successCount+=1
            })
            this.fields.forEach(item => {
                if(!item.noheader)
                    this.headers.push({'text':item,'value':'rec.'+item})
            });
            this.headers.push({'text':'Success','value':'success'})
            this.headers.push({'text':'Message','value':'msg'})
            this.createCardItemArr()    
        },
        createCardItemArr(){
            var data={}
            this.items.forEach(doc=>{
                this.headers.forEach(item=>{
                    if(doc.rec.hasOwnProperty(item.text))
                        data[item.text]=doc.rec[item.text]
                })
                if(doc.msg)
                    data['error']=doc.msg
                data['success']=doc.success
                this.cardItems.push(data)
                data={}
            })
        }
    },
}
</script>
