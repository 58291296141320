<template>
<v-card class="mt-2">
    <v-flex v-for="(listItem,listIndex) in filteredArr" :key="listIndex">
        <v-card class="ma-3">
            <span v-for="(items,keys,index) of listItem" :key="index">
                <v-layout class="mx-4" v-if="keys=='error'" style="color:red">
                    <v-flex xs4>
                        {{keys.charAt(0).toUpperCase() + keys.slice(1)}} :
                    </v-flex>
                    <v-flex xs8>
                        {{items}}<br>
                    </v-flex>
                </v-layout>
                <v-layout class="mx-4" v-else>
                    <v-flex xs4>
                        {{keys.charAt(0).toUpperCase() + keys.slice(1)}} :
                    </v-flex>
                    <v-flex v-if="keys=='success'" xs8>
                        <label v-if="items">Success</label>
                        <v-icon v-if="items" class="active-icon">mdi-check-circle-outline</v-icon>
                        <label v-if="!items">Failed</label>
                        <v-icon v-if="!items" class="deactive-icon">mdi-cancel</v-icon><br>
                    </v-flex>
                    <v-flex v-else xs8>
                        {{items.toString().replace(/\[.*\]/g,'')}}<br>
                    </v-flex>
                </v-layout>
            </span>
        </v-card>
    </v-flex>
</v-card>
</template>

<script>
export default {
    props: {
        arrItems: {
            type: Array,
            default: () => []
        },
        show:{
            type:String,
            default:()=>false
        }
    },
    computed: {
        filteredArr() {
            var itemArr=[]
            if(this.show=='Failed'){
                this.arrItems.forEach(item=>{
                    if(!item.success)
                        itemArr.push(item)
                })
                return itemArr
            }
            else
                return this.arrItems
        }
    },
}
</script>

<style scoped>
.title-font {
    font-size: 14px;
    font-weight: bold;
}

.subtitle-font {
    font-size: 14px;
}
</style>
